<script setup>
import { useI18n } from 'vue-i18n'
const { $confirm, $notify } = useNuxtApp()
const { isLogged } = useAuth()

const { post, preview } = defineProps({ post: Object, preview: Boolean })
const i18n = useI18n()

const date = computed( () => new Date(post?.date).toLocaleDateString(i18n.locale.value, {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric" }))

async function toggleVisibility () {
  const { error } = await useFetch(`/api/post/detail/${post.id}`, { method: 'PATCH', body: { visible: !post.visible} })
  if (error.value) {
    $notify("Error! " + error.value.statusError)
    return
  }
  emit('toggleVisibility')
}

const image = computed(() => post.image ? `/media/${post.image}` : '/media/fallbackImage.png')
const imageWebP = computed(() => post.image ? `/media/${post.image.replace(/.jpg$/, '.webp')}` : '/media/fallbackImage.png')

const emit = defineEmits(['remove', 'toggleVisibility'])

async function remove () {
  const ret = await $confirm(`Are you sure yo want to remove post <u>${post.title}</u>?`)
  if (!ret) return
  const { error } = await useFetch(`/api/post/${post.id}`, { method: 'DELETE', })
  if (error.value) {
    $notify("Error removing post: " + error.value.statusError)
    return
  }
  $notify('Removed', 'success')
  emit('remove')
}

</script>
<template>
  <article class="post" :class="{ hidden: post?.visible === false }">
    <!-- images has a webp version with a jpg fallback and an error fallback image -->
    <nuxt-link :to="post.URL" class="media" target="_blank">
      <v-img   aspect-ratio="4/3" height="155" :src="image" rounded="0" cover>
        <template v-slot:sources>
          <source :srcset="imageWebP" />
        </template>
        <template v-slot:error>
          <v-img src="/media/fallbackImage.png" />
        </template>
      </v-img>
    </nuxt-link>

    <section class="content">
      <span class="navbar">
        <span v-if="post?.tags?.length" class="tags flex-wrap">
          <v-chip label :to='`/tag/${tag.id}`' v-for='tag in post?.tags.slice(0, 5)' :key='tag.id' variant="outlined" size='small' class='mr-1 mb-1'>{{tag.name}}</v-chip>
        </span>
        <v-toolbar-items v-if="!preview" class="actions">
          <v-btn icon="mdi-text" variant="text" alt="delete" size="small" :to="`/p/${post.id}`"></v-btn>
  
          <v-btn v-if='isLogged'
            icon="mdi-delete"
            color='red'
            variant="text"
            size="small"
            @click="remove" />

          <v-btn v-if='isLogged'
            :icon="post?.visible ? 'mdi-eye-off' : 'mdi-eye'"
            color='orange'
            variant="text"
            size="small"
            @click="toggleVisibility"></v-btn>
        </v-toolbar-items>
      </span>
      <div class="title"><nuxt-link :href='post.URL' target="_blank" class='font-weight-bold text-secondary'>{{post.title}}</nuxt-link></div>
      <div class='summary text-caption text-medium-emphasis' v-text='post.summary' />
      <span class='font-weight-light text-caption footer' v-if="post.source">{{ date }} / <nuxt-link class='text-secondary' :to='`/s/${post.source.id}`'>{{post.source.name || post.source.link}}</nuxt-link></span>
      <span class='font-weight-light text-caption footer' v-else>{{ date }}</span>        
    </section>
  </article>
</template>
<style>

.post{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    background-color: #f3f3f3;
    border: 1px solid #ddd;

    /* max-height: 155px; */
    overflow: hidden;
    /* min-height: 200px; */

    width: 100%;
    margin-bottom: 15px;
}

.post .navbar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  height: 32px;
  overflow: hidden;
}

.post .navbar .tags {
  padding-top: 3px;
}

.post .media {
  /* width: 200px; */
  max-height: 155px;
  overflow: hidden;
  flex: 2 1 200px;
  background: none;
  height: 100%;
}

.media picture,
.media img {
  aspect-ratio: 1.7778;
  object-fit: cover;
  height: 100%;
  width: 100%;
}

.hidden {
  opacity: 0.4;
}

.post .content {
  overflow: hidden;
  padding: 5px 10px 5px 10px;
  flex: 2 1 600px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: 155px;
}

.content .summary {
  max-height: 60px;
  overflow: hidden;
  flex-grow: 1;
  text-overflow: ellipsis;
}


.content a {
  color: rgba(var(--v-theme-primary));
}

.content .title {
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 1;
}


</style>